<template>
  <router-link
    class="card"
    :to="{ path: '/newstaff/detail', query: { id: dataSource.taskId } }"
  >
    <div class="head">{{ dataSource.taskName }}</div>
    <div class="info">
      <div class="time">
        {{ $t("Pub_Tab_Time") }}：
        <!-- 时间： -->
        {{ dateFormat(dataSource.startTime) }} -
        {{ dateFormat(dataSource.endTime) }}
      </div>
      <div class="bottom">
        <div class="text">
          <div>
            <UserOutlined />{{
              $t("LB_NumPersonLearning", [dataSource.learnUsers])
            }}
          </div>
          <!-- 人学习 -->
          <div v-if="dataSource.endTime * 1000 < now">
            <CalendarOutlined />{{ $t("XB_HasEnded") }}
            <!-- 已结束 -->
          </div>
          <div v-else>
            <CalendarOutlined />{{
              $t("project.left_over", [timeRmaining(dataSource.endTime)])
            }}
            <!-- 剩余 -->
          </div>
        </div>
        <div class="progress">
          <!-- 已通过 -->
          <span class="num" v-if="dataSource.qualifier == 1">{{
            $t("Pub_Passed")
          }}</span>
          <template v-else-if="dataSource.progress !== 100">
            <span class="num">{{ dataSource.progress }}%</span>
            <a-progress
              type="circle"
              strokeColor="#1ab700"
              :percent="dataSource.progress"
              :width="42"
              :strokeWidth="22"
              :showInfo="false"
            />
          </template>
          <!-- 未通过 -->
          <span class="num" v-else-if="dataSource.qualifier == 2">{{
            $t("CM_NoPass")
          }}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { timeRmaining, dateFormat } from "@/utils/tools";
export default {
  name: "ProjectCard",
  props: {
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup() {
    let now = new Date().getTime();
    return {
      now,
      timeRmaining,
      dateFormat,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  display: block;
  width: 588px;
  height: 240px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
  overflow: hidden;
  background-color: #fff;
  transition: all 0.3s ease;
  .head {
    height: 100px;
    padding: 0 28px 0 44px;
    background-color: #69708b;
    color: #fff;
    font-size: 28px;
    .mixinEllipsis(100px);
    position: relative;
    &::before {
      content: "";
      width: 4px;
      height: 28px;
      background-color: #fff;
      position: absolute;
      left: 28px;
      top: 36px;
    }
  }
  .info {
    padding: 28px;
    .time {
      color: #999;
      font-size: 18px;
      line-height: 1.5;
    }
    .bottom {
      .mixinFlex(space-between; flex-end);
      margin-top: 20px;
      .text {
        .mixinFlex();
        color: #666;
        font-size: 18px;
        line-height: 1.5;
        & > div {
          margin-right: 24px;
          span {
            margin-right: 8px;
          }
        }
      }
      .progress {
        .mixinFlex(space-between; flex-end);
        .num {
          font-size: 26px;
          color: #1ab700;
          margin-right: 14px;
        }
      }
    }
  }
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.25);
  }
}
</style>
